<article class="card grid gap-2 mb-2">
    <div class="grid grid-cols-[1fr_1.75rem] gap-4 items-center font-bold">
        <span class="text-text">Active users</span>
        <app-badge color="success" [value]="usersData.active"></app-badge>
    </div>
    <div class="grid grid-cols-[1fr_1.75rem] gap-4 items-center font-bold">
        <span class="text-text">Inactive users</span>
        <app-badge color="danger" [value]="usersData.inactive"></app-badge>
    </div>
    <div class="grid grid-cols-[1fr_1.75rem] gap-4 items-center font-bold">
        <span class="text-text">Candidates</span>
        <app-badge color="primary" [value]="usersData.candidates"></app-badge>
    </div>
</article>
