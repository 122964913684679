<ion-header mode="ios">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button (click)="closeModal.emit()">
                Cancel
            </ion-button>
        </ion-buttons>
        <ion-title class="uppercase">{{ title }}</ion-title>
        <ion-buttons slot="end">
            <ng-content></ng-content>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
