import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { IonDatetime } from '@ionic/angular';
import { BaseInput } from '../base-input.directive';

type Presentation =
  | 'date'
  | 'date-time'
  | 'month'
  | 'month-year'
  | 'time'
  | 'time-date'
  | 'year';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent extends BaseInput<string | undefined> {
  @ViewChild(IonDatetime) picker!: IonDatetime;
  @Input() minDate?: string = undefined;
  @Input() maxDate? = new Date().toISOString();
  @Input() showIcon = true;
  @Input() presentation: Presentation = 'date';
  @Input() dateFormat = 'MMM d, y';
  @Input() minuteValues?: number[] = undefined;
  @Input() showButtons = true;
  @Input() required = false;
  @Input() showBorder = true;
  isDatetimeOpen = false;
  dateSelected(e: any) {
    this.value = e.target.value;
    this.onChange(this.value);
    this.isDatetimeOpen = false;
  }
  resetDate() {
    this.value = undefined;
    this.onChange(this.value);
    this.picker.reset();
  }
}
