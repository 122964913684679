import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

type Control = FormControl<any>;

@Component({
  selector: 'app-interval',
  templateUrl: './interval.component.html',
  styleUrls: ['./interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntervalComponent {
  @Input() label = '';
  @Input() placeholders: [string, string] = ['', ''];
  @Input() controls!: [Control, Control];
  @Input() minDate?: string = undefined;
  @Input() maxDate?: string = undefined;
}
