import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Colors } from '../colors';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() value: string | number = '';
  @Input() fontSize = 'text-xs';
  @Input() color: Colors = 'primary';
  colors = new Map<Colors, string[]>([
    ['primary', ['bg-primary-light', 'text-primary']],
    ['success', ['bg-success-light', 'text-success']],
    ['danger', ['bg-danger-light', 'text-danger']],
    ['medium', ['bg-medium-200', 'text-medium-500']],
    ['urgent', ['bg-danger', 'text-white']],
  ]);
  get badgeClasses() {
    return [...(this.colors.get(this.color) || []), this.fontSize];
  }
}
