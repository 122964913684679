import {
  AfterViewInit,
  ContentChild,
  Directive,
  HostListener,
  Input,
  Renderer2,
  inject,
} from '@angular/core';
import { IonSelect } from '@ionic/angular';

@Directive({
  selector: '[appSelectAllSearch]',
})
export class SelectAllSearchDirective implements AfterViewInit {
  private renderer = inject(Renderer2);
  @ContentChild(IonSelect) ionSelect!: IonSelect;
  @Input('appSelectAllSearch') enabled = true;
  ngAfterViewInit() {
    if (this.enabled) {
      (this.ionSelect as any).el.style.pointerEvents = 'none';
    }
  }
  @HostListener('click', ['$event'])
  async onClick() {
    if (!this.enabled) {
      return;
    }
    const alert: HTMLIonAlertElement = await this.ionSelect.open();
    const checkboxes = Array.from(alert.querySelectorAll('.alert-checkbox'));
    const selectAllPlusSearchEl: any = document.createElement('c-select-all-plus-search');
    selectAllPlusSearchEl.addEventListener('searchChange', (e: CustomEvent) => {
      checkboxes.forEach((c) => {
        const checkbox = c as HTMLElement;
        checkbox.style.display = checkbox.innerText
          ?.toLowerCase()
          ?.includes(e?.detail?.toLowerCase())
          ? 'block'
          : 'none';
      });
    });
    selectAllPlusSearchEl.addEventListener('selectAllChange', (e: CustomEvent) => {
      alert.inputs = alert.inputs.map((i) => {
        i.checked = e.detail;
        return i;
      });
      selectAllPlusSearchEl.indeterminate = false;
    });
    const alertWrapperEl = alert.querySelector('.alert-wrapper');
    const messageEl = alert.querySelector('.alert-wrapper .alert-message');
    alertWrapperEl?.insertBefore(selectAllPlusSearchEl, messageEl);

    const setState = () => {
      const isAllCheckedElsLength =
        checkboxes.filter((c: any) => c.ariaChecked === 'true')?.length ?? 0;
      const isAllChecked = isAllCheckedElsLength == checkboxes?.length;
      if (isAllChecked || isAllCheckedElsLength == 0) {
        selectAllPlusSearchEl.checked = isAllChecked;
        selectAllPlusSearchEl.indeterminate = false;
      } else {
        selectAllPlusSearchEl.indeterminate = true;
      }
    };
    setState();
    checkboxes.map((ci) =>
      this.renderer.listen(ci, 'click', () => {
        setTimeout(setState);
      })
    );
  }
}
