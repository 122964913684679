import { ResolveFn, Routes } from '@angular/router';

export const policyRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@/features/policies/policies-page/policies-page.component'),
  },
  {
    path: ':policy',
    loadComponent: () => import('./policy/policy.page'),
  },
  {
    path: ':policy/reminders',
    loadComponent: () =>
      import('@/features/reminders/reminders-page/reminders-page.component'),
    data: { showCreateModal: true },
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `policies/${paramMap.get('policy')}/reminders`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':policy/notes',
    loadComponent: () => import('@/features/notes/notes-page/notes-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `policies/${paramMap.get('policy')}/notes`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':policy/documents',
    loadComponent: () => import('./documents/documents.page'),
  },
  {
    path: ':policy/documents/:document',
    loadComponent: () =>
      import('@/features/documents/document-page/document-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) => {
        const policy = Number(paramMap.get('policy'));
        const document = Number(paramMap.get('document'));
        return `policies/${policy}/documents/${document}`;
      }) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':policy/addresses',
    loadComponent: () => import('./address/address.page'),
  },
  {
    path: ':policy/members',
    loadComponent: () => import('./members/members.page'),
  },
  {
    path: ':policy/members/:member',
    loadComponent: () => import('./member/member.page'),
  },
  {
    path: ':policy/insurance',
    loadComponent: () => import('./insurance/insurance.page'),
  },
];
