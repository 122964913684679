<article class="card pb-0 pl-0 mb-8">
    <ion-list>
        <ion-item *ngFor="let item of [1,1,1,1,1,1,1,1]">
            <ion-label>
                <ion-skeleton-text animated style="width: 50%;"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </ion-list>
</article>
<article class="card pb-0 pl-0 mb-8">
    <ion-list>
        <ion-item *ngFor="let item of [1,1,1,1,1]">
            <ion-label>
                <ion-skeleton-text animated style="width: 30%;"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </ion-list>
</article>
