import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  provideRouter,
  withComponentInputBinding,
  withPreloading,
} from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { provideQueryClientOptions } from '@ngneat/query';

import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { ApiPrefixInterceptor } from './app/core/interceptors/api-prefix.interceptor';
import { TokenInterceptor } from './app/core/interceptors/token.interceptor';
import { ErrorInterceptor } from './app/core/interceptors/error.interceptor';
import { appRoutes } from './app/routes';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom([
      IonicModule.forRoot(),
      NgxEchartsModule.forRoot({
        echarts: () => import('echarts'),
      }),
    ]),
    provideRouter(
      appRoutes,
      withPreloading(PreloadAllModules),
      withComponentInputBinding()
    ),
    provideHttpClient(
      withInterceptors([ApiPrefixInterceptor, TokenInterceptor, ErrorInterceptor])
    ),
    provideLottieOptions({ player: () => import('lottie-web') }),
    provideCacheableAnimationLoader(),
    provideQueryClientOptions({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
    }),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
});
