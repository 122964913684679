<ion-item class="form-control pt-4" [appControlFeedback]="control">
    <ion-toggle class="font-semibold text-[#555]"
        [color]="color"
        [checked]="value"
        [disabled]="disabled"
        (ionChange)="onInput($event)"
        (ionBlur)="onTouched($event)">
        <span class="whitespace-normal">{{ label }}</span>
    </ion-toggle>
    <ion-note *ngIf="helper" slot="helper">{{ helper }}</ion-note>
    <ion-note slot="error" [appControlError]="errors" />
</ion-item>