export enum PreferredLanguage {
  EN = 'en',
  ES = 'es',
}

export enum RoleId {
  ADMIN = 1,
  AGENT,
  STAFF_AGENT,
  STAFF_LEVEL_2,
  MANAGER,
  RECRUITER,
  STAFF_LEVEL_1,
  CANDIDATE,
  ACCOUNTANT,
}

export enum RoleTitle {
  ADMIN = 'Admin',
  AGENT = 'Agent',
  STAFF_AGENT = 'Staff (Agent)',
  STAFF_LEVEL_2 = 'Staff (Level 2)',
  MANAGER = 'Manager',
  RECRUITER = 'Recruiter',
  STAFF_LEVEL_1 = 'Staff (Level 1)',
  CANDIDATE = 'Candidate',
  ACCOUNTANT = 'Accountant',
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
}

export type PolicyColors =
  | 'brand'
  | 'accent'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger'
  | 'dark';
