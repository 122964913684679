<ion-input
    [ngModel]="value | mask:mask"
    class="form-input hide-helper"
    labelPlacement="floating"
    helperText="placeholder"
    [label]="label"
    [class]="class"
    [class.show-required]="showRequired"
    [type]="type"
    [placeholder]="placeholder"
    [value]="value | mask:mask"
    [disabled]="disabled"
    [maxlength]="maxLength"
    [required]="required"
    [errorText]="errors | controlError"
    (ionInput)="onInput($event)"
    (ionBlur)="onTouched($event)" />