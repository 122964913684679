import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appControlFeedback]',
})
export class ControlFeedbackDirective {
  @Input('appControlFeedback') control!: AbstractControl | null;
  @HostBinding('class.is-valid')
  get valid() {
    return this.control?.valid && this.control?.dirty;
  }
  @HostBinding('class.is-invalid')
  get invalid() {
    return this.control?.invalid && this.control?.dirty;
  }
}
