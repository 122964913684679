import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseInput } from '../base-input.directive';

type InputType = 'text' | 'email' | 'number' | 'tel';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends BaseInput {
  @Input() type: InputType = 'text';
  @Input() maxLength = 255;
  @Input() debounce = 0;
  @Input() min = 0;
  @Input() max = 0;
  @Input() email = false;
  @Input() required = false;
  @Input() showBorder = true;
}
