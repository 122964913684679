import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '@env/environment';

const { apiUrl } = environment;

export const ApiPrefixInterceptor: HttpInterceptorFn = (request, next) => {
  const req = request.clone({
    url: `${apiUrl}/v1/${request.url}`,
  });
  return next(req);
};
