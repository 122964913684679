import { Directive, ElementRef, Inject, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ErrorDictionary, FORM_ERRORS } from './form-validations';

@Directive({
  selector: '[appControlError]',
  exportAs: 'controlError',
})
export class ControlErrorDirective {
  constructor(
    @Inject(FORM_ERRORS) private errors: ErrorDictionary,
    private element: ElementRef<HTMLElement>
  ) {}
  @Input('appControlError')
  set controlErrors(controlErrors: ValidationErrors | null) {
    if (controlErrors) {
      this.setError(controlErrors);
      return;
    }
    this.element.nativeElement.innerText = '';
  }
  private setError(errors: ValidationErrors) {
    const firstKey = Object.keys(errors)[0];
    const getError = this.errors[firstKey] ? this.errors[firstKey] : this.errors[0];
    this.element.nativeElement.innerText = getError(errors[firstKey]);
  }
}
