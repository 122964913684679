<img *ngIf="image && !showPlaceholder; else placeholder"
    class="rounded-full bg-primary-light aspect-square avatar"
    [class]="sizeClass"
    [src]="image"
    [alt]="name"
    (error)="showPlaceholder = true">
<ng-template #placeholder>
    <span class="grid place-content-center rounded-full bg-primary-light text-primary text-3xl aspect-square"
        [class]="sizeClass">
        {{ name[0] }}
    </span>
</ng-template>
