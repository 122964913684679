import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

type Control = FormControl<any>;

@Component({
  selector: 'app-number-interval',
  templateUrl: './number-interval.component.html',
  styleUrls: ['./number-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberIntervalComponent {
  @Input() label = '';
  @Input() placeholders: [string, string] = ['', ''];
  @Input() controls!: [Control, Control];
  @Input() min = 0;
  @Input() max = 0;
}
