<form [formGroup]="form">
    <h4 class="uppercase font-semibold mb-2 pl-4 text-text opacity-70">
        Personal information
    </h4>
    <article class="card mb-8 py-0">
        <ion-list>
            <app-input formControlName="first_name" label="First name" />
            <app-input formControlName="last_name" label="Last name" />
            <app-datepicker formControlName="dob" label="Date of Birth" />
            <app-select formControlName="gender"
                label="Gender"
                [options]="genders" />
            <app-input formControlName="npn"
                label="NPN"
                [maxLength]="50" />
            <app-input formControlName="email" label="Email address" />
            <app-masked-input formControlName="phone"
                label="Phone"
                mask="+0(000)000-0000"
                [maxLength]="15"
                [onlyNumbers]="true" />
            <app-masked-input formControlName="cellphone"
                label="Cellphone"
                mask="+0(000)000-0000"
                [maxLength]="15"
                [onlyNumbers]="true" />
        </ion-list>
    </article>
    <h4 class="uppercase font-semibold mb-2 pl-4 text-text opacity-70">
        Address
    </h4>
    <article class="card py-0">
        <ion-list>
            <app-input formControlName="address_1" label="Address line #1" />
            <app-input formControlName="address_2" label="Address line #2" />
            <app-input formControlName="city" label="City" />
            <ng-container *ngIf="states$ | async as states; else loading">
                <app-select formControlName="state"
                    label="State / Province"
                    [options]="states" />
            </ng-container>
            <app-input formControlName="zip" label="Zip code" />
        </ion-list>
    </article>
</form>
<ng-template #loading>
    <ion-item class="form-control" detail>
        <ion-skeleton-text animated style="width: 50%" />
    </ion-item>
</ng-template>