import { Pipe, PipeTransform } from '@angular/core';
import { User } from './user.models';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(user: User): string {
    if (!user) {
      return '';
    }
    const { first_name, last_name } = user;
    return `${first_name} ${last_name}`;
  }
}
