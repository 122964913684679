import { ResolveFn, Routes } from '@angular/router';

export const userRoutes: Routes = [
  {
    path: 'share',
    loadComponent: () => import('./user-share/user-share.page'),
  },
  {
    path: 'create',
    loadComponent: () => import('./create-user/create-user.page'),
  },
  {
    path: ':id',
    loadComponent: () => import('./user-detail/user-detail.page'),
  },
  {
    path: ':id/edit',
    loadComponent: () => import('./user-edit/user-edit.page'),
  },
  {
    path: ':id/reminders',
    loadComponent: () =>
      import('@/features/reminders/reminders-page/reminders-page.component'),
    data: { showCreateModal: false },
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `users/${paramMap.get('id')}/reminders`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':id/notes',
    loadComponent: () => import('@/features/notes/notes-page/notes-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `users/${paramMap.get('id')}/notes`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':id/documents',
    loadComponent: () =>
      import('@/features/documents/documents-page/documents-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `users/${paramMap.get('id')}/documents`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':id/documents/:document',
    loadComponent: () =>
      import('@/features/documents/document-page/document-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) => {
        const user = paramMap.get('id');
        const document = paramMap.get('document');
        return `users/${user}/documents/${document}`;
      }) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':id/licenses',
    loadComponent: () =>
      import('@/features/licenses/licenses-page/licenses-page.component'),
    resolve: {
      userId: (({ paramMap }) => paramMap.get('id')) satisfies ResolveFn<string | null>,
    },
  },
  { path: '', loadComponent: () => import('./users.page') },
];
