import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
import { Platform } from '@ionic/angular';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private platform: Platform) {}
  async setupAnalytics() {
    const enabled = this.platform.is('ios')
      ? await this.checkAppTrackingTransparencyPermission()
      : true;
    try {
      await FirebaseAnalytics.initializeFirebase(environment.firebase);
    } catch (error) {
      console.log('firebase app already initialized');
    }
    await FirebaseAnalytics.setCollectionEnabled({ enabled });
  }
  setUserId(userId: string) {
    return FirebaseAnalytics.setUserId({ userId });
  }
  logScreenName(screenName: string) {
    return FirebaseAnalytics.setScreenName({ screenName, nameOverride: screenName });
  }
  private async checkAppTrackingTransparencyPermission() {
    const { status } = await AppTrackingTransparency.getStatus();
    if (status === 'notDetermined') {
      const permissionStatus = await AppTrackingTransparency.requestPermission();
      return permissionStatus.status === 'authorized';
    }
    return status === 'authorized';
  }
}
