import { ChangeDetectionStrategy, Component, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { IonMenu, IonicModule } from '@ionic/angular';
import { AuthService } from '@/core/services/auth.service';
import { UserService } from '@/core/services/user.service';
import { UserModule } from '@/features/user/user.module';
import { injectVersion } from '@/core/helpers/version';
import { AvatarComponent } from '../avatar/avatar.component';
import { SvgIconPipe } from '../svg-icon.pipe';
import { map } from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    IonicModule,
    AvatarComponent,
    UserModule,
    SvgIconPipe,
  ],
})
export class MenuComponent {
  @ViewChild(IonMenu) menu!: IonMenu;
  private userServ = inject(UserService);
  private authServ = inject(AuthService);
  version$ = injectVersion();
  disableMenu$ = this.authServ.authUser$.pipe(map((user) => !user));
  profile$ = this.userServ.profile$;
  links$ = this.userServ.hasMainRole$.pipe(
    map((hasMainRole) => [
      {
        route: '/home',
        label: 'Dashboard',
        icon: 'table-columns',
        show: true,
      },
      {
        route: '/policies',
        label: 'Policies',
        icon: 'file-lock',
        show: true,
      },
      {
        route: '/calendar',
        label: 'Calendar',
        icon: 'calendar-days',
        show: true,
      },
      {
        route: '/users',
        label: 'Users',
        icon: 'user',
        show: hasMainRole,
      },
      {
        route: '/contacts',
        label: 'Contacts',
        icon: 'users',
        show: true,
      },
      {
        route: '/about',
        label: 'About',
        icon: 'memo-circle-info',
        show: true,
      },
    ])
  );
  logout() {
    this.menu.close();
    this.authServ.logout();
  }
}
