<ion-input
    [(ngModel)]="inputValue"
    class="form-input"
    type="text"
    labelPlacement="floating"
    helperText="To add a value enter a comma (,)"
    [label]="label"
    [class]="class"
    [class.show-required]="showRequired"
    [type]="type"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [debounce]="debounce"
    [maxlength]="maxLength"
    [email]="type === 'email'"
    [required]="required"
    [min]="min"
    [max]="max"
    [errorText]="errors | controlError"
    (ionInput)="checkInputValue()"
    (ionBlur)="onTouched($event)" />
<div class="flex flex-wrap gap-2">
    <ion-chip *ngFor="let chip of value" (click)="removeValue(chip)">
        <ion-label>{{ chip }}</ion-label>
        <ion-icon name="close" />
    </ion-chip>
</div>