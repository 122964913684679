import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { mainUserRoles } from '@/features/user/user.models';
import { ConfigurationService } from '../services/configuration.service';
import { UserService } from '../services/user.service';
import { RoleId } from '../models/enums';
import { map, of, shareReplay, switchMap } from 'rxjs';

export const injectIdParam = (param: string) =>
  inject(ActivatedRoute).paramMap.pipe(
    map((params) => Number(params.get(param))),
    shareReplay(1)
  );

export const injectResourceUrl = () =>
  inject(ActivatedRoute).data.pipe(map((data) => data.resourceUrl as string));

export const injectRouteData = <T>(dataProp: string) =>
  inject(ActivatedRoute).data.pipe(map((data) => data[dataProp] as T));

export const injectActiveUserOpts = () =>
  inject(ConfigurationService)
    .getActiveUsers()
    .pipe(
      map((users) =>
        users.map(({ id, first_name, last_name }) => ({
          id,
          label: `${first_name} ${last_name}`,
        }))
      )
    );

export const injectStaffOpts = () =>
  inject(ConfigurationService)
    .getAgencyStaff()
    .pipe(
      map((staff) =>
        staff.map(({ id, full_name }) => ({
          id,
          label: full_name,
        }))
      )
    );

export const injectMembers = () => {
  const activeUsers$ = injectActiveUserOpts();
  const staffUsers$ = injectStaffOpts();
  return inject(UserService).profile$.pipe(
    map((profile) => profile.role_id),
    switchMap((role) => {
      if (mainUserRoles.includes(role)) {
        return activeUsers$;
      } else if (role === RoleId.AGENT) {
        return staffUsers$;
      } else {
        return of([]);
      }
    })
  );
};

export const injectProfileId = () =>
  inject(UserService).profile$.pipe(map((profile) => profile.id));

export const injectStates = () =>
  inject(ConfigurationService)
    .getStates()
    .pipe(map((states) => states.map(({ code, name }) => ({ id: code, label: name }))));

export const injectCountries = () =>
  inject(ConfigurationService)
    .getCountries()
    .pipe(
      map((countries) => countries.map(({ code, name }) => ({ id: code, label: name })))
    );

export const injectCarriers = () =>
  inject(ConfigurationService)
    .getInsuranceCompanies()
    .pipe(map((companies) => companies.map(({ id, name }) => ({ id, label: name }))));
