import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { UseQuery, filterSuccess } from '@ngneat/query';
import { Configuration, StaffUser } from '../models/configuration.models';
import { catchError, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  private http = inject(HttpClient);
  private useQuery = inject(UseQuery);
  configCache$ = this.useQuery({
    queryKey: ['configuration'],
    queryFn: () => this.http.get<Configuration>('app/configuration'),
    staleTime: Infinity,
  }).result$.pipe(
    filterSuccess(),
    map(({ data }) => data)
  );
  getActiveUsers() {
    return this.configCache$.pipe(map(({ active_users }) => active_users));
  }
  getStates() {
    return this.configCache$.pipe(map(({ states }) => states));
  }
  getCountries() {
    return this.configCache$.pipe(map(({ countries }) => countries));
  }
  getRoles() {
    return this.configCache$.pipe(map(({ roles }) => roles));
  }
  getPolicyStatus() {
    return this.configCache$.pipe(map(({ policy_status }) => policy_status));
  }
  getInsuranceCompanies() {
    return this.configCache$.pipe(map(({ insurance_companies }) => insurance_companies));
  }
  getAgencyStaff() {
    return this.useQuery({
      queryKey: ['staff'],
      queryFn: () => this.http.get<StaffUser[]>('app/staff'),
      staleTime: Infinity,
    }).result$.pipe(
      filterSuccess(),
      map(({ data }) => data ?? []),
      catchError(() => of([] as StaffUser[]))
    );
  }
}
