import { Routes } from '@angular/router';
import { AccessGuard } from './core/guards/access.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { canLoadAndActivate } from './core/guards/guard.helpers';
import { PublicGuard } from './core/guards/public.guard';
import { dashboardRoutes } from './pages/dashboard/routes';

export const appRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page'),
    ...canLoadAndActivate(PublicGuard),
  },
  {
    path: '',
    loadComponent: () => import('./pages/dashboard/dashboard.page'),
    ...canLoadAndActivate(AuthGuard, AccessGuard),
    children: dashboardRoutes,
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
