import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { ControlFeedbackDirective } from './control-feedback.directive';
import { ControlErrorDirective } from './control-error.directive';
import { InputComponent } from './input/input.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { SelectComponent } from './select/select.component';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { ToggleComponent } from './toggle/toggle.component';
import { IntervalComponent } from './interval/interval.component';
import { MaskedInputComponent } from './masked-input/masked-input.component';
import { NumberIntervalComponent } from './number-interval/number-interval.component';
import { ControlErrorPipe } from './control-error.pipe';
import { SelectAllSearchComponent } from './select/select-all-search/select-all-search.component';
import { SelectAllSearchDirective } from './select/select-all-search/select-all-search.directive';
import { DatetimePickerComponent } from './datetime-picker/datetime-picker.component';
import { ChipsInputComponent } from './chips-input/chips-input.component';

@NgModule({
  declarations: [
    ControlFeedbackDirective,
    ControlErrorDirective,
    InputComponent,
    PasswordInputComponent,
    SelectComponent,
    DatepickerComponent,
    DatetimePickerComponent,
    ToggleComponent,
    IntervalComponent,
    MaskedInputComponent,
    NumberIntervalComponent,
    ControlErrorPipe,
    SelectAllSearchComponent,
    SelectAllSearchDirective,
    ChipsInputComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    ControlFeedbackDirective,
    ControlErrorDirective,
    InputComponent,
    PasswordInputComponent,
    SelectComponent,
    DatepickerComponent,
    DatetimePickerComponent,
    ToggleComponent,
    IntervalComponent,
    MaskedInputComponent,
    NumberIntervalComponent,
    ChipsInputComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule, ReactiveFormsModule, NgxMaskPipe],
  providers: [provideNgxMask()],
})
export class FormUtilitiesModule {}
