import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConfigurationService } from '@/core/services/configuration.service';
import { UserFilters, UserFiltersForm } from '../user.models';
import { map } from 'rxjs';

@Component({
  selector: 'app-users-filter',
  templateUrl: './users-filter.component.html',
  styleUrls: ['./users-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersFilterComponent {
  @Output() closeFilter = new EventEmitter();
  @Output() filterData = new EventEmitter<Partial<UserFilters>>();
  @Input() isOpen = false;
  @Input() filters!: FormGroup<UserFiltersForm>;
  roles$ = this.configServ
    .getRoles()
    .pipe(map((roles) => roles.map(({ id, title }) => ({ id, label: title }))));
  status = [
    { id: true, label: 'Active' },
    { id: false, label: 'Inactive' },
  ];
  constructor(private configServ: ConfigurationService) {}
  applyFilters() {
    const { value } = this.filters;
    this.filterData.emit(value);
    this.closeFilter.emit();
  }
  reset() {
    this.filters.reset();
    this.applyFilters();
  }
}
