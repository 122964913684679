// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://qa.apizeal.io',
  analyticsId: '',
  cognito: {
    UserPoolId: 'us-east-1_rtQiTSAzq',
    ClientId: '1qo4fhrcpiu9gj9f0tvbro31t0',
  },
  links: {
    site: 'https://apizeal.com',
    terms: 'https://apizeal.com/terms-of-use/',
    privacy: 'https://apizeal.com/privacy-policy/',
    license: 'https://apizeal.com/license-agreement/',
    forgotPassword: 'https://app.apizeal.com/forgot-password',
    help: 'https://docs.apizeal.com',
    youtube: 'https://www.youtube.com/@Apizeal',
  },
  firebase: {
    apiKey: 'AIzaSyDOpayqLcOL-DNCbU5k-2fo3ac7gUpsJY0',
    authDomain: 'apizeal-mobile.firebaseapp.com',
    projectId: 'apizeal-mobile',
    storageBucket: 'apizeal-mobile.appspot.com',
    messagingSenderId: '354554770086',
    appId: '1:354554770086:web:bebabcbb3e3b046015ba67',
    measurementId: 'G-0RZHTFJKZ2',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
