import { Routes } from '@angular/router';
import { canLoadAndActivate } from '@/core/guards/guard.helpers';
import { RoleGuard } from '@/core/guards/role.guard';
import { userRoutes } from './users/routes';
import { policyRoutes } from './policies/routes';
import { contactsRoutes } from './contacts/routes';
import { profileRoutes } from './profile/routes';

export const dashboardRoutes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./home/home.page'),
  },
  {
    path: 'policies',
    children: policyRoutes,
  },
  {
    path: 'contacts',
    children: contactsRoutes,
  },
  {
    path: 'calendar',
    loadComponent: () => import('./events/events.page'),
  },
  {
    path: 'users',
    children: userRoutes,
    ...canLoadAndActivate(RoleGuard),
  },
  {
    path: 'profile',
    children: profileRoutes,
  },
  {
    path: 'about',
    loadComponent: () => import('./about/about.page'),
  },
  { path: '**', pathMatch: 'full', redirectTo: 'home' },
];
