import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { BaseInput } from '../base-input.directive';

type InputType = 'text' | 'number' | 'tel';

@Component({
  selector: 'app-masked-input',
  templateUrl: './masked-input.component.html',
  styleUrls: ['./masked-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskedInputComponent extends BaseInput {
  @ViewChild(IonInput) ionInputEl!: IonInput;
  @Input() type: InputType = 'text';
  @Input() maxLength = 255;
  @Input() onlyNumbers = false;
  @Input() required = false;
  @Input() mask!: string;
  value = '';
  onInput(ev: any) {
    if (this.onlyNumbers) {
      const value = ev.target.value;
      // Removes non alphanumeric characters
      const filteredValue = value.replace(/[^0-9]+/g, '');
      /**
       * Update both the state variable and
       * the component to keep them in sync.
       */
      this.ionInputEl.value = this.value = filteredValue;
    } else {
      this.value = ev.target.value;
    }
    this.onChange(this.value);
  }
}
