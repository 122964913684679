import { Gender, PreferredLanguage, RoleId, RoleTitle } from '@/core/models/enums';
import { ControlsOf } from '@/shared/form-utilities/form-validations';

export interface User {
  id: number;
  code: string;
  avatar: null | string;
  first_name: string;
  last_name: string;
  email: string;
  phone: null | string;
  cellphone: null | string;
  dob: null | string;
  gender: null | Gender;
  preferred_language: PreferredLanguage;
  address_1: null | string;
  address_2: null | string;
  city: null | string;
  state: null | string;
  zip: null | string;
  status: boolean;
  role_id: RoleId;
  role_title: RoleTitle;
  organization_id: number;
  username: null | string;
  npn: null | string;
  created: string;
  last_update: string;
}

export interface UsersData {
  active: number;
  inactive: number;
  candidates: number;
}

export interface UserDto {
  first_name: string | null;
  last_name: string | null;
  dob: string | null;
  gender: string | null;
  npn: string | null;
  email: string | null;
  phone: string | null;
  cellphone: string | null;
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
}

export interface UserCreateDto {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  role_id: RoleId | null;
  preferred_language: PreferredLanguage | null;
}

export type UserCreateForm = ControlsOf<UserCreateDto>;

export interface UserFilters {
  search: string | null;
  role_id: RoleId[] | null;
  status: boolean | null;
}

export type UserFiltersForm = ControlsOf<UserFilters>;

export const nonAgencyRoles = [RoleId.AGENT, RoleId.CANDIDATE];
export const mainUserRoles = [RoleId.ADMIN, RoleId.MANAGER];
