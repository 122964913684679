import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { FormUtilitiesModule } from '@/shared/form-utilities/form-utilities.module';
import { LayoutModule } from '@/shared/layout/layout.module';
import { AvatarComponent } from '@/shared/ui/avatar/avatar.component';
import { BadgeComponent } from '@/shared/ui/badge/badge.component';
import { SvgIconPipe } from '@/shared/ui/svg-icon.pipe';

import { UserSummaryComponent } from './user-summary/user-summary.component';
import { UserItemComponent } from './user-item/user-item.component';
import { FullNamePipe } from './full-name.pipe';
import { UsersSkeletonComponent } from './users-skeleton/users-skeleton.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UsersFilterComponent } from './users-filter/users-filter.component';
import { SkeletonUserFormComponent } from './skeleton-user-form/skeleton-user-form.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [
    UserProfileComponent,
    UserSummaryComponent,
    UserItemComponent,
    UsersSkeletonComponent,
    UserFormComponent,
    UsersFilterComponent,
    SkeletonUserFormComponent,
    FullNamePipe,
  ],
  exports: [
    UserProfileComponent,
    UserSummaryComponent,
    UserItemComponent,
    UsersSkeletonComponent,
    UserFormComponent,
    UsersFilterComponent,
    SkeletonUserFormComponent,
    FullNamePipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    FormUtilitiesModule,
    LayoutModule,
    AvatarComponent,
    BadgeComponent,
    NgxMaskPipe,
    SvgIconPipe,
  ],
  providers: [provideNgxMask()],
})
export class UserModule {}
