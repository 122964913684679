import { Directive, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Directive()
export class BaseInput<T = string> implements ControlValueAccessor {
  @Input() showRequired = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() class = '';
  @Input() disabled = false;
  value!: T;
  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  get errors() {
    return this.ngControl.errors;
  }
  get control() {
    return this.ngControl.control;
  }
  onChange: any = () => {};
  onTouched: any = () => {};
  onInput(event: any) {
    this.value = event.target.value;
    this.onChange(this.value);
  }
  writeValue(value: T) {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
