<article class="card pb-0 pl-0 mb-8">
    <div class="flex gap-4 items-center justify-center flex-col text-text pl-4 mb-6">
        <app-avatar [image]="user.avatar" [name]="user | fullName" sizeClass="w-28"></app-avatar>
        <div>
            <h4 class="font-semibold">{{ user | fullName }}</h4>
            <p>Role: {{ user.role_title }}</p>
            <!-- <p class="text-primary" (click)="changeAvatar()">Change avatar</p> -->
        </div>
    </div>
    <ion-list>
        <ion-item class="text-text">
            <ion-label class="!flex justify-between gap-2 pl-4">
                <span class="font-semibold">Email</span>
                <span>{{ user.email }}</span>
            </ion-label>
        </ion-item>
        <ion-item class="text-text">
            <ion-label class="!flex justify-between gap-2 pl-4">
                <span class="font-semibold">Telephone</span>
                <span *ngIf="user.phone">{{ user.phone | mask:'+0(000)000-0000' }}</span>
            </ion-label>
        </ion-item>
        <ion-item class="text-text">
            <ion-label class="!flex justify-between gap-2 pl-4">
                <span class="font-semibold">NPN</span>
                <span *ngIf="user.npn">{{ user.npn }}</span>
            </ion-label>
        </ion-item>
        <ion-item class="text-text">
            <ion-label class="!flex justify-between gap-2 pl-4">
                <span class="font-semibold">Created</span>
                <span>{{ user.created | date:'MMM d, y' }}</span>
            </ion-label>
        </ion-item>
        <ion-item class="text-text" lines="none">
            <ion-label class="!flex justify-between gap-2 pl-4">
                <span class="font-semibold">Last update</span>
                <span>{{ user.last_update | date:'MMM d, y hh:mm a' }}</span>
            </ion-label>
        </ion-item>
    </ion-list>
</article>
<h4 class="uppercase font-semibold mb-2 pl-8 text-text opacity-70">Menu options</h4>
<article class="card py-0">
    <ion-list>
        <ion-item class="text-text" button detail routerLink="./edit">
            <ion-icon slot="start" name="person" color="primary" />
            <ion-label class="font-semibold pl-4">Profile</ion-label>
        </ion-item>
        <ion-item *ngIf="hasMainRole$ | async" class="text-text" button detail routerLink="./licenses">
            <ion-icon slot="start" [src]="'file-certificate' | svgIcon" color="primary" />
            <ion-label class="font-semibold pl-4">Licenses</ion-label>
        </ion-item>
        <ion-item class="text-text" button detail routerLink="./documents">
            <ion-icon slot="start" [src]="'folders' | svgIcon" color="primary" />
            <ion-label class="font-semibold pl-4">Documents</ion-label>
        </ion-item>
        <ion-item class="text-text" button detail routerLink="./reminders">
            <ion-icon slot="start" name="notifications" color="primary" />
            <ion-label class="font-semibold pl-4">Reminders</ion-label>
        </ion-item>
        <ion-item class="text-text" button detail lines="none" routerLink="./notes">
            <ion-icon slot="start" [src]="'note' | svgIcon" color="primary" />
            <ion-label class="font-semibold pl-4">Notes</ion-label>
        </ion-item>
    </ion-list>
</article>