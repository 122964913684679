<ion-header mode="ios">
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button *ngIf="showBackBtn; else menuBtn" [defaultHref]="defaultBackRoute" />
            <ng-template #menuBtn>
                <ion-menu-button />
            </ng-template>
            <ng-content select="[start]" />
        </ion-buttons>
        <ion-title class="uppercase" [class]="titleSize">{{ title }}</ion-title>
        <ion-buttons slot="end">
            <ng-content />
        </ion-buttons>
    </ion-toolbar>
</ion-header>
