import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { QueryClientService } from '@ngneat/query';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import * as AWSCognito from 'amazon-cognito-identity-js';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private queryClient = inject(QueryClientService);
  private authUser = new BehaviorSubject<AWSCognito.CognitoUser | null>(null);
  private token = new BehaviorSubject<string | null>(null);
  private readonly userPool = new AWSCognito.CognitoUserPool(environment.cognito);
  get authUser$() {
    return this.authUser.asObservable();
  }
  get token$() {
    return this.token.asObservable();
  }
  getStoredSession() {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (!cognitoUser) {
        return resolve();
      }
      cognitoUser.getSession(
        (error: Error | null, session: AWSCognito.CognitoUserSession) => {
          if (error) {
            return reject(error.message || JSON.stringify(error));
          }
          this.setLoggedIn(cognitoUser, session);
          resolve();
        }
      );
    });
  }
  async login(email: string, password: string) {
    const authDetails = new AWSCognito.AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const cognitoUser = new AWSCognito.CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    return new Promise<void>((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (session) => {
          this.setLoggedIn(cognitoUser, session);
          resolve();
        },
        onFailure: (error) => {
          reject(error.message || JSON.stringify(error));
        },
      });
    });
  }
  logout() {
    this.authUser.value?.signOut();
    this.authUser.next(null);
    this.token.next(null);
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }
  private setLoggedIn(
    user: AWSCognito.CognitoUser,
    session: AWSCognito.CognitoUserSession
  ) {
    const token = session.getAccessToken().getJwtToken();
    this.token.next(token);
    this.authUser.next(user);
    this.queryClient.invalidateQueries(['profile']);
    this.router.navigateByUrl('/', { replaceUrl: true });
  }
}
