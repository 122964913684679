import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'svgIcon',
  standalone: true,
})
export class SvgIconPipe implements PipeTransform {
  transform(icon: string): string {
    return `assets/icons/${icon}.svg`;
  }
}
