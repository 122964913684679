import {
  ChangeDetectionStrategy,
  Component,
  Injector,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BaseInput } from '../base-input.directive';
import { SelectAllSearchComponent } from './select-all-search/select-all-search.component';

interface Option {
  id: string | number | boolean;
  label: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent extends BaseInput<string | null> implements OnInit {
  private injector = inject(Injector);
  @Input() options: Option[] = [];
  @Input() interface: 'action-sheet' | 'alert' | 'popover' = 'alert';
  @Input() multiple = false;
  @Input() required = false;
  ngOnInit(): void {
    if (this.multiple && !customElements.get('c-select-all-plus-search')) {
      const element = createCustomElement(SelectAllSearchComponent, {
        injector: this.injector,
      });
      customElements.define(`c-select-all-plus-search`, element);
    }
  }
  get hasValue() {
    return this.multiple ? (this.value?.length ?? 0) > 0 : this.value !== null;
  }
  resetValue() {
    if (this.hasValue) {
      this.onInput({ target: { value: null } });
    }
  }
}
