<div class="flex gap-2">
    <ion-input
        class="form-input hide-helper flex-1"
        labelPlacement="floating"
        helperText="placeholder"
        [ngModel]="value"
        [label]="label"
        [type]="show ? 'text' : 'password'"
        [class]="class"
        [placeholder]="placeholder"
        [value]="value"
        [disabled]="disabled"
        [required]="true"
        [errorText]="errors | controlError"
        (ionInput)="onInput($event)"
        (ionBlur)="onTouched($event)" />
    <ion-button class="aspect-square self-center h-9"
        type="button"
        fill="clear"
        shape="round"
        (click)="show = !show">
        <ion-icon slot="icon-only" [name]="show ? 'eye-off-outline' : 'eye-outline'" />
    </ion-button>
</div>