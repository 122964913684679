<ion-input
    [ngModel]="value"
    class="form-input hide-helper"
    labelPlacement="floating"
    [helperText]="showBorder ? 'placeholder' : undefined"
    [label]="label"
    [class]="class"
    [class.show-required]="showRequired"
    [type]="type"
    [placeholder]="placeholder"
    [value]="value"
    [disabled]="disabled"
    [debounce]="debounce"
    [maxlength]="maxLength"
    [email]="email"
    [required]="required"
    [min]="min"
    [max]="max"
    [errorText]="errors | controlError"
    (ionInput)="onInput($event)"
    (ionBlur)="onTouched($event)" />