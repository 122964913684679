import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-select-all-search',
  templateUrl: './select-all-search.component.html',
  styleUrls: ['./select-all-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectAllSearchComponent {
  @Output() selectAllChange = new EventEmitter();
  @Output() searchChange = new EventEmitter();
  @Input() checked = false;
  @Input() indeterminate = false;
  @Input() searchText = '';
}
