import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { UIService } from '../services/ui.service';
import { catchError, retry, throwError, timer } from 'rxjs';

export const skipErrorInterceptorHeader = 'X-Skip-Error-Interceptor';
const maxRetries = 2;
const delayMs = 1000;

let errorAlert: HTMLIonAlertElement;

const shouldRetry = (error: HttpErrorResponse) => {
  const status = error.status || 500;
  if (status === 500) {
    return timer(delayMs);
  }
  throw error;
};

const manageError = (error: any, uiServ: UIService) => {
  const defaultMsg = 'Internal server error, try again later';
  const errorMsg =
    error?.error instanceof ErrorEvent
      ? `Error: ${error?.error?.message}`
      : `Error ${error?.status || 500}: ${
          error?.error?.error || error?.error?.message || defaultMsg
        }`;
  errorAlert?.dismiss();
  uiServ.showErrorAlert(errorMsg).then((alert) => (errorAlert = alert));
  return throwError(() => new Error(errorMsg));
};

export const ErrorInterceptor: HttpInterceptorFn = (request, next) => {
  const uiServ = inject(UIService);
  const headers = request.headers;
  if (headers.has(skipErrorInterceptorHeader)) {
    const clone = request.clone({
      headers: headers.delete(skipErrorInterceptorHeader),
    });
    return next(clone);
  }
  return next(request).pipe(
    retry({ count: maxRetries, delay: shouldRetry }),
    catchError((error) => manageError(error, uiServ))
  );
};
