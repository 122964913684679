<a [routerLink]="['.', user.id]"
    class="card px-4 py-2 grid gap-2 grid-cols-[3rem_auto_4rem] items-center">
    <app-avatar [image]="user.avatar" [name]="user | fullName" />
    <div class="grid text-sm min-w-0">
        <p class="text-primary font-medium">{{ user | fullName }}</p>
        <p class="text-text flex gap-1 items-center">
            <span>{{ user.email }}</span>
        </p>
        <p class="text-text">
            <span class="font-medium">Role</span>: {{ user.role_title }}
        </p>
    </div>
    <app-badge class="self-start"
        fontSize="text-xxs"
        [color]="user.status ?'success':'danger'"
        [value]="user.status ? 'active' : 'inactive'" />
</a>