<ion-menu #menu [disabled]="disableMenu$ | async" contentId="main-content">
    <ion-content class="p-4">
        <a *ngIf="profile$ | async as profile"
            routerLink="/profile"
            class="flex flex-col gap-2 items-center justify-center py-8"
            (click)="menu.close()">
            <app-avatar [image]="profile.avatar" [name]="profile | fullName" sizeClass="w-28"></app-avatar>
            <div>
                <h4 class="font-semibold">{{ profile | fullName }}</h4>
                <p>Role: {{ profile.role_title }}</p>
            </div>
            <p>{{ profile.email }}</p>
        </a>
        <ion-list>
            <ng-container *ngFor="let link of links$ | async; let i = index">
                <ion-item *ngIf="link.show"
                    button
                    lines="none"
                    class="text-xl"
                    [routerLink]="link.route"
                    routerLinkActive="text-primary"
                    [routerLinkActiveOptions]="{ exact: i === 0 }"
                    (click)="menu.close()">
                    <ion-icon slot="start" size="large" [src]="link.icon | svgIcon" />
                    <ion-label>{{ link.label }}</ion-label>
                </ion-item>
            </ng-container>
            <ion-item button lines="none" class="text-xl" (click)="logout()">
                <ion-icon slot="start" size="large" [src]="'right-from-bracket' | svgIcon" />
                <ion-label>Sign Out</ion-label>
            </ion-item>
        </ion-list>
    </ion-content>
    <ion-footer class="ion-no-border p-4">
        <p class="text-center text-sm">Version {{ version$ | async }}</p>
    </ion-footer>
</ion-menu>