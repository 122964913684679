<ion-item class="form-control" [appControlFeedback]="control" [appSelectAllSearch]="multiple">
    <ion-select
        [ngModel]="value"
        class="form-input"
        labelPlacement="floating"
        [class]="class"
        [class.show-required]="showRequired"
        [interface]="multiple ? 'alert' : interface"
        [multiple]="multiple"
        [interfaceOptions]="{ header: label, cssClass: 'custom-select-interface' }"
        [value]="value"
        [disabled]="disabled"
        [required]="required"
        [cancelText]="hasValue ? 'Reset' : 'Cancel'"
        (ionChange)="onInput($event)"
        (ionBlur)="onTouched($event)"
        (ionCancel)="resetValue()">
        <span slot="label" class="font-semibold select-label">{{ label }}</span>
        <ion-select-option *ngFor="let option of options" [value]="option.id">
            {{ option.label }}
        </ion-select-option>
    </ion-select>
    <ion-note slot="helper">{{ multiple ? 'Please select one or more' : 'Please select one' }}</ion-note>
    <ion-note slot="error" [appControlError]="errors" />
</ion-item>