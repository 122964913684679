import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-users-skeleton',
  templateUrl: './users-skeleton.component.html',
  styleUrls: ['./users-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSkeletonComponent {
  items = Array(20);
}
