<div class="flex gap-4 items-center pl-4">
    <ion-checkbox
        [ngModel]="checked"
        [indeterminate]="indeterminate"
        (ngModelChange)="selectAllChange.emit($event)" />
    <ion-input
        class="flex-1"
        placeholder="Search"
        clearInput
        [ngModel]="searchText"
        (ngModelChange)="searchChange.emit($event)" />
</div>