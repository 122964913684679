import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { environment } from '@env/environment';
import { first, switchMap } from 'rxjs/operators';

const { apiUrl } = environment;

export const TokenInterceptor: HttpInterceptorFn = (request, next) => {
  if (!request.url.includes(apiUrl)) {
    return next(request);
  }
  return inject(AuthService).token$.pipe(
    first(),
    switchMap((token) => {
      const clone = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next(clone);
    })
  );
};
