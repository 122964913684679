import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { IonDatetime } from '@ionic/angular';
import { BaseInput } from '../base-input.directive';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatetimePickerComponent extends BaseInput<string | undefined> {
  @ViewChild(IonDatetime) picker!: IonDatetime;
  @Input() minDate?: string = undefined;
  @Input() maxDate? = new Date().toISOString();
  @Input() showIcon = true;
  @Input() dateFormat = 'MMM d, y';
  @Input() timeFormat = 'hh:mm a';
  @Input() minuteValues?: number[] = undefined;
  @Input() showButtons = true;
  @Input() required = false;
  @Input() showBorder = true;
  openPicker: 'date' | 'time' | null = null;
  dateSelected(e: any) {
    this.value = e.target.value;
    this.onChange(this.value);
    this.openPicker = null;
  }
  resetDate() {
    this.value = undefined;
    this.onChange(this.value);
    this.picker.reset();
  }
}
