<ion-modal [isOpen]="isOpen" (willDismiss)="closeFilter.emit()">
    <ng-template>
        <app-modal-header title="Filter" (closeModal)="closeFilter.emit()">
            <ion-button (click)="reset()">
                Reset
            </ion-button>
            <ion-button (click)="applyFilters()">
                <span class="font-semibold">Apply</span>
            </ion-button>
        </app-modal-header>
        <ion-content class="p-4">
            <form [formGroup]="filters" class="card pt-0 pb-2">
                <ion-list>
                    <div class="flex gap-2">
                        <div class="flex-1">
                            <app-input formControlName="search" label="Type here to search..." />
                        </div>
                        <ion-icon class="self-center w-6 h-6" color="primary" icon="search" />
                    </div>
                    <ng-container *ngIf="roles$ | async as roles; else loading">
                        <app-select formControlName="role_id"
                            label="Filter by role"
                            [multiple]="true"
                            [options]="roles" />
                    </ng-container>
                    <app-select formControlName="status"
                        label="Filter by status"
                        [options]="status" />
                </ion-list>
            </form>
        </ion-content>
    </ng-template>
    <ng-template #loading>
        <ion-item class="form-control" detail>
            <ion-skeleton-text animated style="width: 50%" />
        </ion-item>
    </ng-template>
</ion-modal>