import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { map, tap } from 'rxjs/operators';

export const AuthGuard = () => {
  const router = inject(Router);
  return inject(AuthService).authUser$.pipe(
    map((user) => !!user),
    tap((loggedIn) => !loggedIn && router.navigateByUrl('/login'))
  );
};
