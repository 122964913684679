import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { errorsDictionary } from './form-validations';

@Pipe({
  name: 'controlError',
})
export class ControlErrorPipe implements PipeTransform {
  readonly errors = errorsDictionary;
  transform(errors: ValidationErrors | null): string {
    if (!errors) {
      return '';
    }
    const firstKey = Object.keys(errors)[0];
    const getError = this.errors[firstKey] ? this.errors[firstKey] : this.errors[0];
    return getError(errors[firstKey]);
  }
}
