import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { UserService } from '@/core/services/user.service';
import { User } from '../user.models';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  @Input() user!: User;
  hasMainRole$ = inject(UserService).hasMainRole$;
}
