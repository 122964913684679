<ion-item button detail="false" class="form-control" lines="none" (click)="isDatetimeOpen = true">
    <ion-input
        [ngModel]="value | date:dateFormat"
        class="form-input hide-helper"
        labelPlacement="floating"
        type="text"
        [helperText]="showBorder ? 'placeholder' : undefined"
        [label]="label"
        [class]="class"
        [class.show-required]="showRequired"
        [placeholder]="placeholder"
        [value]="value | date:dateFormat"
        [disabled]="disabled"
        [required]="required"
        [errorText]="errors | controlError"
        (ionBlur)="onTouched($event)" />
    <ion-icon *ngIf="showIcon" class="self-center" name="calendar-outline" slot="end" />
</ion-item>

<ion-modal class="datetime-modal"
    [class.has-buttons]="showButtons"
    [keepContentsMounted]="true"
    [isOpen]="isDatetimeOpen"
    (willDismiss)="isDatetimeOpen = false">
    <ng-template>
        <ion-content>
            <ion-datetime #datetime
                size="cover"
                locale="en-US"
                [presentation]="presentation"
                [max]="maxDate"
                [min]="minDate"
                [minuteValues]="minuteValues"
                [showDefaultButtons]="showButtons"
                (ionCancel)="isDatetimeOpen = false"
                (ionChange)="dateSelected($event)">
                <ion-buttons slot="buttons">
                    <ion-button color="danger" (click)="resetDate()">Reset</ion-button>
                    <ion-button color="primary" (click)="datetime.cancel()">Cancel</ion-button>
                    <ion-button color="primary" (click)="datetime.confirm()">Done</ion-button>
                </ion-buttons>
            </ion-datetime>
        </ion-content>
    </ng-template>
</ion-modal>