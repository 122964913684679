import { ResolveFn, Routes } from '@angular/router';
import { policyRoutes } from '../policies/routes';

const [, ...policyRoutesWithoutRoot] = policyRoutes;

export const contactsRoutes: Routes = [
  { path: ':contact', loadComponent: () => import('./contact-info/contact-info.page') },
  {
    path: ':contact/profile',
    loadComponent: () => import('./contact-profile/contact-profile.page'),
  },
  {
    path: ':contact/settings',
    loadComponent: () => import('./contact-settings/contact-settings.page'),
  },
  {
    path: ':contact/reminders',
    loadComponent: () =>
      import('@/features/reminders/reminders-page/reminders-page.component'),
    data: { showCreateModal: true },
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `contacts/${paramMap.get('contact')}/reminders`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':contact/notes',
    loadComponent: () => import('@/features/notes/notes-page/notes-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `contacts/${paramMap.get('contact')}/notes`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':contact/documents',
    loadComponent: () =>
      import('@/features/documents/documents-page/documents-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) =>
        `contacts/${paramMap.get('contact')}/documents`) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':contact/documents/:document',
    loadComponent: () =>
      import('@/features/documents/document-page/document-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) => {
        const contact = paramMap.get('contact');
        const document = paramMap.get('document');
        return `contacts/${contact}/documents/${document}`;
      }) satisfies ResolveFn<string>,
    },
  },
  {
    path: ':contact/policies',
    children: [
      ...policyRoutesWithoutRoot,
      {
        path: '',
        loadComponent: () =>
          import('@/features/policies/policies-page/policies-page.component'),
        data: { showBackBtn: true },
        resolve: {
          baseFilters: (({ paramMap }) => ({
            client_id: paramMap.get('contact'),
          })) satisfies ResolveFn<Record<string, string | null>>,
        },
      },
    ],
  },
  { path: '', loadComponent: () => import('./contacts.page') },
];
