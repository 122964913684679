import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

interface PromptOptions {
  header: string;
  message: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UIService {
  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController
  ) {}
  async showErrorAlert(message: string, header = 'An error has ocurred') {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['Ok'],
    });
    await alert.present();
    return alert;
  }
  async toast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      color: 'primary',
      duration: 3500,
      position: 'top',
    });
    await toast.present();
  }
  async createLoading(message = 'Please wait...') {
    const loading = await this.loadingCtrl.create({
      message,
      translucent: true,
      spinner: 'bubbles',
    });
    loading.present();
    return loading;
  }
  async prompt({
    header,
    message,
    confirmBtnText = 'Ok',
    cancelBtnText = 'Cancel',
  }: PromptOptions) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      cssClass: 'prompt-alert',
      buttons: [
        { text: cancelBtnText, role: 'cancel' },
        { text: confirmBtnText, role: 'confirm' },
      ],
    });
    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role === 'confirm';
  }
}
