import { inject } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { of, switchMap, from, map } from 'rxjs';

export const injectVersion = () =>
  of(inject(Platform).is('capacitor')).pipe(
    switchMap((isNative) => (isNative ? from(App.getInfo()) : of({ version: '2.0' }))),
    map(({ version }) => version)
  );
