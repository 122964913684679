import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseInput } from '../base-input.directive';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent extends BaseInput {
  show = false;
}
