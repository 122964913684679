import { InjectionToken } from '@angular/core';
import {
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
  FormControl,
  FormGroup,
} from '@angular/forms';

export type ControlsOf<T extends Record<string, any>> = {
  [K in keyof T]: T[K] extends Record<any, any>
    ? FormGroup<ControlsOf<T[K]>>
    : FormControl<T[K]>;
};

export class CustomValidators {
  static matchConfirmation(originalKey: string, confirmKey: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const original = control.get(originalKey);
      const confirm = control.get(confirmKey);
      return original?.value !== confirm?.value
        ? { notMatchingConfirmation: { original: originalKey } }
        : null;
    };
  }
}

export type ErrorDictionary = Record<string, (error: any) => string>;
export const errorsDictionary: ErrorDictionary = {
  invalid: () => `This field's value is invalid`,
  required: () => `This field is required`,
  email: () => `This field must have a valid email`,
  minlength: ({ requiredLength }: any) =>
    `This field must have a minimum length of ${requiredLength}`,
  maxlength: ({ requiredLength }: any) =>
    `This field must have a maximum length of ${requiredLength}`,
  min: ({ min }: any) => `This fields's value must not be less than ${min}`,
  max: ({ max }: any) => `This fields's value must not be more than ${max}`,
  pattern: () => `This field's value is invalid`,
  notMatchingConfirmation: ({ original }: any) =>
    `The confirmation of ${original} does not match`,
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => errorsDictionary,
});
