import { ResolveFn, Route } from '@angular/router';
import { injectProfileId } from '@/core/helpers/providers';
import { map } from 'rxjs';

export const profileRoutes: Route[] = [
  {
    path: 'edit',
    loadComponent: () => import('./edit-profile/edit-profile.page'),
  },
  {
    path: 'reminders',
    loadComponent: () =>
      import('@/features/reminders/reminders-page/reminders-page.component'),
    data: { showCreateModal: false },
    resolve: {
      resourceUrl: (() =>
        injectProfileId().pipe(
          map((id) => `users/${id}/reminders`)
        )) satisfies ResolveFn<string>,
    },
  },
  {
    path: 'notes',
    loadComponent: () => import('@/features/notes/notes-page/notes-page.component'),
    resolve: {
      resourceUrl: (() =>
        injectProfileId().pipe(
          map((id) => `users/${id}/notes`)
        )) satisfies ResolveFn<string>,
    },
  },
  {
    path: 'documents',
    loadComponent: () =>
      import('@/features/documents/documents-page/documents-page.component'),
    resolve: {
      resourceUrl: (() =>
        injectProfileId().pipe(
          map((id) => `users/${id}/documents`)
        )) satisfies ResolveFn<string>,
    },
  },
  {
    path: 'documents/:document',
    loadComponent: () =>
      import('@/features/documents/document-page/document-page.component'),
    resolve: {
      resourceUrl: (({ paramMap }) => {
        const document = paramMap.get('document');
        return injectProfileId().pipe(map((id) => `users/${id}/documents/${document}`));
      }) satisfies ResolveFn<string>,
    },
  },
  {
    path: 'licenses',
    loadComponent: () =>
      import('@/features/licenses/licenses-page/licenses-page.component'),
    resolve: {
      userId: (() => injectProfileId()) satisfies ResolveFn<number>,
    },
  },
  { path: '', loadComponent: () => import('./profile.page') },
];
