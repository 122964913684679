import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToggleCustomEvent } from '@ionic/angular';
import { IonColors } from '../../ui/colors';
import { BaseInput } from '../base-input.directive';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent extends BaseInput<boolean> {
  @Input() color: IonColors = 'primary';
  @Input() helper = '';
  onInput(e: Event) {
    const event = e as ToggleCustomEvent<boolean>;
    this.value = event.detail.checked;
    this.onChange(this.value);
  }
}
