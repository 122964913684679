<div class="grid gap-4 grid-cols-2">
    <ion-item button
        detail="false"
        class="form-control"
        lines="none"
        [disabled]="disabled"
        (click)="openPicker = 'date'">
        <ion-input
            [ngModel]="value | date:dateFormat"
            class="form-input hide-helper"
            labelPlacement="floating"
            type="text"
            [helperText]="showBorder ? 'placeholder' : undefined"
            [label]="label + ' date'"
            [class]="class"
            [class.show-required]="showRequired"
            [placeholder]="placeholder"
            [value]="value | date:dateFormat"
            [disabled]="disabled"
            [required]="required"
            [errorText]="errors | controlError"
            (ionBlur)="onTouched($event)" />
        <ion-icon *ngIf="showIcon" class="self-center" name="calendar-outline" slot="end" />
    </ion-item>
    <ion-item button
        detail="false"
        class="form-control"
        lines="none"
        [disabled]="disabled || !value"
        (click)="openPicker = 'time'">
        <ion-input
            [ngModel]="value | date:timeFormat"
            class="form-input hide-helper"
            labelPlacement="floating"
            type="text"
            [helperText]="showBorder ? 'placeholder' : undefined"
            [label]="label + ' time'"
            [class]="class"
            [class.show-required]="showRequired"
            [placeholder]="placeholder"
            [value]="value | date:timeFormat"
            [disabled]="disabled || !value"
            [required]="required"
            [errorText]="errors | controlError"
            (ionBlur)="onTouched($event)" />
        <ion-icon *ngIf="showIcon" class="self-center" name="time-outline" slot="end" />
    </ion-item>
</div>

<ion-modal [class]="openPicker === 'date' ? 'datetime-modal' : 'time-modal'"
    [class.has-buttons]="showButtons"
    [keepContentsMounted]="true"
    [isOpen]="!!openPicker"
    (willDismiss)="openPicker = null">
    <ng-template>
        <ion-content>
            <ion-datetime #datetime
                size="cover"
                locale="en-US"
                [presentation]="openPicker"
                [max]="maxDate"
                [min]="minDate"
                [minuteValues]="minuteValues"
                [showDefaultButtons]="showButtons"
                (ionCancel)="openPicker = null"
                (ionChange)="dateSelected($event)">
                <ion-buttons slot="buttons">
                    <ion-button color="danger" (click)="resetDate()">Reset</ion-button>
                    <ion-button color="primary" (click)="datetime.cancel()">Cancel</ion-button>
                    <ion-button color="primary" (click)="datetime.confirm()">Done</ion-button>
                </ion-buttons>
            </ion-datetime>
        </ion-content>
    </ng-template>
</ion-modal>