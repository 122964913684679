import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueryClientService, UseQuery, filterSuccess } from '@ngneat/query';
import { RoleId } from '@/core/models/enums';
import {
  mainUserRoles,
  nonAgencyRoles,
  User,
  UserCreateDto,
  UserDto,
  UserFilters,
} from '../../features/user/user.models';
import { AuthService } from './auth.service';
import { AnalyticsService } from './analytics.service';
import { UserAccess } from '../models/configuration.models';
import { catchError, lastValueFrom, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { createHttpParams } from '../helpers/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);
  private authServ = inject(AuthService);
  private analyticsServ = inject(AnalyticsService);
  private useQuery = inject(UseQuery);
  private queryClient = inject(QueryClientService);
  pageSize = 10;
  isLoggedIn$ = this.authServ.authUser$.pipe(map((user) => !!user));
  profile$ = this.isLoggedIn$.pipe(
    switchMap(
      (isLoggedIn) =>
        this.useQuery({
          queryKey: ['profile'],
          queryFn: () => this.http.get<User>(`users/profile`),
          enabled: isLoggedIn,
        }).result$
    ),
    filterSuccess(),
    map(({ data }) => data),
    tap((user) => this.analyticsServ.setUserId(user.id.toString()))
  );
  hasMainRole$ = this.profile$.pipe(
    map((user) => user.role_id),
    map((role) => mainUserRoles.includes(role))
  );
  private route = 'users';
  checkUserHasAccess() {
    return this.useQuery({
      queryKey: ['access'],
      queryFn: () => this.http.get<UserAccess>('app/access'),
      cacheTime: 1000,
    }).result$.pipe(
      filterSuccess(),
      map(({ data }) => data.mobile_app),
      map(({ code, description }) => {
        if (code !== 'approved') {
          this.authServ.logout();
          return { hasAccess: false, description };
        }
        return { hasAccess: true, description };
      })
    );
  }
  getUsers(page: number, filters: Partial<UserFilters>) {
    const params = createHttpParams({
      ...filters,
      start: page * this.pageSize,
      length: this.pageSize,
    });
    return this.http.get<User[]>(this.route, { params }).pipe(
      map((users) => users || []),
      catchError(() => of([] as User[]))
    );
  }
  getActiveUsers(role_id?: RoleId) {
    const params = createHttpParams({ role_id, status: true });
    return this.http.get<User[]>(this.route, { params }).pipe(
      map((users) => users || []),
      catchError(() => of([] as User[])),
      map((users) => users.filter((user) => !nonAgencyRoles.includes(user.role_id)))
    );
  }
  getUser(id: number) {
    return this.http.get<User>(`${this.route}/${id}`);
  }
  updateUser(id: number, user: Partial<UserDto>) {
    const request = this.http.put<User>(`${this.route}/${id}`, user);
    return lastValueFrom(request);
  }
  createUser(user: Partial<UserCreateDto>) {
    const request = this.http.post<User>(this.route, user);
    return lastValueFrom(request);
  }
  reloadProfile() {
    this.queryClient.invalidateQueries(['profile']);
  }
}
