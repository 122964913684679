import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@/core/services/user.service';
import { tap } from 'rxjs';

export const RoleGuard = () => {
  const router = inject(Router);
  return inject(UserService).hasMainRole$.pipe(
    tap((hasRole) => {
      if (!hasRole) {
        router.navigateByUrl('/home');
      }
    })
  );
};
