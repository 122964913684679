import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UsersData } from '../user.models';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSummaryComponent {
  @Input() usersData!: UsersData;
}
