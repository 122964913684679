import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { InfiniteScrollCustomEvent, IonInfiniteScroll } from '@ionic/angular';

@Component({
  selector: 'app-infinite-list',
  templateUrl: './infinite-list.component.html',
  styleUrls: ['./infinite-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfiniteListComponent {
  @ViewChild(IonInfiniteScroll) infiniteScroll!: IonInfiniteScroll;
  @Output() loadMore = new EventEmitter<InfiniteScrollCustomEvent>();
  @Input() listOf = '';
  @Input() listGap = 'gap-4';
  emitLoadMore(event: Event) {
    this.loadMore.emit(event as InfiniteScrollCustomEvent);
  }
}
