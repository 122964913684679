import { ControlsOf } from '@/shared/form-utilities/form-validations';
import { Gender, PreferredLanguage } from './enums';

export interface Address {
  country?: string;
  city: string;
  state: string;
  zip: string;
  address_1: string;
  address_2: string;
}

export interface Employment {
  income: number;
  company: string;
  company_phone: string;
  position: string;
}

export interface AddressDto {
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
}

export interface EmploymentDto {
  company: string | null;
  company_phone: string | null;
  position: string | null;
  income: number | null;
}

export type AddressForm = ControlsOf<AddressDto>;
export type EmploymentForm = ControlsOf<EmploymentDto>;

export const languageOptions = [
  { id: PreferredLanguage.EN, label: 'English' },
  { id: PreferredLanguage.ES, label: 'Spanish' },
];

export const genderOptions = [
  { id: Gender.MALE, label: 'Male' },
  { id: Gender.FEMALE, label: 'Female' },
];
