<div class="pt-4">
    <p class="text-text font-semibold mb-2">{{ label }}</p>
    <div class="grid grid-cols-[1fr_auto_1fr]">
        <div class="border-y border-l border-text border-opacity-30 pl-2 rounded-l">
            <app-input *ngIf="controls?.[0]"
                type="number"
                [formControl]="controls[0]"
                [placeholder]="placeholders[0]"
                [min]="min"
                [max]="max"
                [showBorder]="false" />
        </div>
        <span
            class="bg-medium-200 border border-text border-opacity-30 text-text text-xl grid place-content-center px-2">
            <ion-icon name="ellipsis-horizontal" />
        </span>
        <div class="border-y border-r border-text border-opacity-30 pl-2 rounded-r">
            <app-input *ngIf="controls?.[1]"
                type="number"
                [formControl]="controls[1]"
                [placeholder]="placeholders[1]"
                [min]="controls[0].value || undefined"
                [max]="max"
                [showBorder]="false" />
        </div>
    </div>
    <hr class="border-text border-opacity-20 mt-4">
</div>