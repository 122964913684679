import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonicModule, Platform } from '@ionic/angular';
import { StatusBar, Style } from '@capacitor/status-bar';
import { AnalyticsService } from './core/services/analytics.service';
import { AuthService } from './core/services/auth.service';
import { NativeOnly } from './core/helpers/decorators';
import { MenuComponent } from './shared/ui/menu/menu.component';
import { environment } from '../environments/environment';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  template: `
    <ion-app>
      <app-menu />
      <ion-router-outlet id="main-content" />
    </ion-app>
  `,
  styles: [],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IonicModule, MenuComponent],
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  private platform = inject(Platform);
  private analyticsServ = inject(AnalyticsService);
  private authServ = inject(AuthService);
  constructor() {
    this.showSplashscreen();
  }
  async ngOnInit() {
    if (!environment.production) {
      await this.authServ.getStoredSession();
    }
    await this.nativeSetup();
    this.analyticsSetup();
  }
  @NativeOnly()
  private async nativeSetup() {
    await this.platform.ready();
    await StatusBar.setStyle({ style: Style.Light });
  }
  private async analyticsSetup() {
    await this.analyticsServ.setupAnalytics();
    this.routerNavigationEnd().subscribe((item: NavigationEnd) => {
      this.analyticsServ.logScreenName(item.url);
    });
  }
  private routerNavigationEnd() {
    return this.router.events.pipe(
      filter((item) => item instanceof NavigationEnd),
      map((item) => item as NavigationEnd)
    );
  }
  private async showSplashscreen() {
    await this.platform.ready();
    const { lottie } = window as any;
    if (this.platform.is('ios') && lottie) {
      await lottie.splashscreen.hide();
      await lottie.splashscreen.show('public/assets/lotties/splash.json', false);
    }
  }
}
