import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { injectStates } from '@/core/helpers/providers';
import { genderOptions } from '@/core/models/member.models';
import { ControlsOf } from '@/shared/form-utilities/form-validations';
import { User, UserDto } from '../user.models';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFormComponent {
  form!: FormGroup<ControlsOf<UserDto>>;
  states$ = injectStates();
  genders = genderOptions;
  private userData!: User;
  constructor(private fb: FormBuilder) {}
  @Input()
  set user(user: User) {
    this.form = this.setupForm(user);
    this.userData = user;
  }
  submit() {
    const { email } = this.userData;
    const { valid, value } = this.form;
    const dto = {
      ...value,
      email,
    };
    return { valid, dto };
  }
  private setupForm(user: User) {
    const { first_name, last_name, dob, gender, npn, email, phone, cellphone } = user;
    const { address_1, address_2, city, state, zip } = user;
    return this.fb.nonNullable.group<ControlsOf<UserDto>>({
      first_name: this.fb.control(first_name || '', Validators.required),
      last_name: this.fb.control(last_name || '', Validators.required),
      dob: this.fb.control(dob || ''),
      gender: this.fb.control(gender || ''),
      npn: this.fb.control(npn || '', Validators.maxLength(50)),
      email: this.fb.control({ value: email, disabled: true }, [
        Validators.required,
        Validators.email,
      ]),
      phone: this.fb.control(phone || ''),
      cellphone: this.fb.control(cellphone || ''),
      address_1: this.fb.control(address_1 || ''),
      address_2: this.fb.control(address_2 || ''),
      city: this.fb.control(city || ''),
      state: this.fb.control(state || ''),
      zip: this.fb.control(zip || ''),
    });
  }
}
