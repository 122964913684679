import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SvgIconPipe } from '@/shared/ui/svg-icon.pipe';
import { HeaderComponent } from './header/header.component';
import { ModalHeaderComponent } from './modal-header/modal-header.component';
import { EmptyComponent } from './empty/empty.component';
import { InfiniteListComponent } from './infinite-list/infinite-list.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ModalHeaderComponent,
    EmptyComponent,
    InfiniteListComponent,
  ],
  exports: [HeaderComponent, ModalHeaderComponent, EmptyComponent, InfiniteListComponent],
  imports: [CommonModule, IonicModule, SvgIconPipe],
})
export class LayoutModule {}
