<article class="card grid gap-2 mb-2">
    <div *ngFor="let item of [1,1,1]" class="flex gap-4 justify-between items-center font-bold">
        <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
        <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
    </div>
</article>
<ul class="grid gap-2">
    <li *ngFor="let item of items" class="card px-4 py-2 grid gap-2 grid-cols-[4rem_auto_4rem] items-center">
        <ion-avatar>
            <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <div class="grid text-xs min-w-0">
            <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
        </div>
        <div class="self-start">
            <ion-skeleton-text animated style="width: 100%"></ion-skeleton-text>
        </div>
    </li>
</ul>
