/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Capacitor } from '@capacitor/core';

export function NativeOnly() {
  return (target: object, propertyKey: string, descriptor: PropertyDescriptor) => {
    const originalMethod = descriptor.value;
    const isNative = Capacitor.isNativePlatform();
    // prettier-ignore
    descriptor.value = function(...args: unknown[]) {
      if (isNative) {
        const result = originalMethod.apply(this, args);
        return result;
      }
    };
    return descriptor;
  };
}
