import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseInput } from '../base-input.directive';

type InputType = 'text' | 'email' | 'number' | 'tel';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsInputComponent extends BaseInput<string[]> {
  @Input() type: InputType = 'text';
  @Input() maxLength = 255;
  @Input() debounce = 0;
  @Input() min = 0;
  @Input() max = 0;
  @Input() required = false;
  inputValue = '';
  checkInputValue() {
    if (this.inputValue.includes(',')) {
      this.addValue(this.inputValue.replace(',', ''));
      this.inputValue = '';
    }
  }
  addValue(value: string) {
    this.value.push(value);
    this.onChange(this.value);
  }
  removeValue(value: string) {
    this.value = this.value.filter((v) => v !== value);
    this.onChange(this.value);
    this.inputValue = '';
    console.log(this.inputValue);
  }
}
