import { HttpParams } from '@angular/common/http';

export const createFormData = <T extends object>(data: T) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    if (value instanceof File) {
      formData.append(key, value, value.name);
    } else if (Array.isArray(value)) {
      formData.append(key, value.join(','));
    } else if (typeof value === 'boolean') {
      formData.append(key, value ? '1' : '0');
    } else if (value !== null) {
      formData.append(key, value.toString());
    } else {
      formData.append(key, '');
    }
  }
  return formData;
};

export const createHttpParams = <T extends object>(data: T) => {
  let params = new HttpParams();
  for (const [key, value] of Object.entries(data)) {
    if (value === null || value === undefined) {
      continue;
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        params = params.append(key, value.filter((v) => v !== null).join(','));
      }
    } else if (value instanceof Date) {
      params = params.append(key, value.toISOString());
    } else if (value.toString().length > 0) {
      params = params.append(key, value.toString());
    }
  }
  return params;
};

export const downloadFile = (file: Blob) => {
  const downloadUrl = URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.download = 'file.pdf';
  link.click();
};
